import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles, labels, tooltips } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{ title: classes.cardHeader }}
          title={intl.formatMessage(titles.Title_UserOverview_GeneralDataTab_GeneralDataSection)}
          data-testid="user-overview-general-data-section-title"
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField
              xs={12}
              sm={12}
              md={12}
              xl={6}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_FullName)}
              data={props?.userGeneralDataOverview?.fullName}
              id="fullName"
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_IdentificationNumber
              )}
              data={props?.userGeneralDataOverview?.identityCard}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_BirthDate)}
              data={
                props?.userGeneralDataOverview?.dateOfBirth
                  ? moment(props?.userGeneralDataOverview?.dateOfBirth).format("DD/MM/YYYY")
                  : ""
              }
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Gender)}
              data={props?.userGeneralDataOverview?.genderDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_MaritalStatus)}
              data={props?.userGeneralDataOverview?.maritalStatusDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Household)}
              data={props?.userGeneralDataOverview?.household ?? "0"}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_FamilyIncome)}
              data={props?.userGeneralDataOverview?.familyIncome ?? "0"}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeeds)}
              data={props?.userGeneralDataOverview?.specialNeedsDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeedsPercentage
              )}
              data={props?.userGeneralDataOverview?.specialNeedsPercentage ?? "0"}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Nationality)}
              data={props?.userGeneralDataOverview?.nationalityDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Province)}
              data={props?.userGeneralDataOverview?.provinceDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Municipality)}
              data={props?.userGeneralDataOverview?.municipalityDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_District)}
              data={props?.userGeneralDataOverview?.district}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={
                <>
                  {intl.formatMessage(
                    labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanySocialSecurityNumber
                  )}
                  <Tooltip title={intl.formatMessage(tooltips.Tooltip_Social_Security_Number_Description)}>
                    <HelpIcon className={classes.tooltipInfoIcon} />
                  </Tooltip>
                </>
              }
              data={props?.userGeneralDataOverview?.niss}
              id="niss"
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(GeneralDataSection);
