import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import CandidateGeneralDetails from "./Sections/CandidateGeneralDetails";
import CandidateProfessionalDetails from "./Sections/CandidateProfessionalDetails";
import DocumentsSection from "./Sections/DocumentsSection";
import CandidateAnswers from "./Sections/CandidateAnswers";
import CandidateRejectedMotive from "./Sections/CandidateRejectedMotive";
import ApproveDialog from "components/Dialogs/ApproveDialog";
import RejectDialog from "components/Dialogs/RejectDialog";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import {
  getCandidateInformationAction,
  approveOpportunityCandidateAction,
  rejectOpportunityCandidateAction,
} from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { OPPORTUNITY_TYPE_KEY, DATASET } from "utils/consts";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateDetails(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();
  const [candidateInfo, setCandidateInfo] = useState();
  const [candidateId, setCandidateId] = useState();

  const methods = useForm({
    mode: "onChange",
  });

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  useEffect(() => {
    props.getCandidateInformation(props.match.params.alternateKey);
    if (props.opportunitiesState.candidateInfo !== null) {
      setCandidateInfo(props.opportunitiesState.candidateInfo);
      setCandidateId(props.match.params.alternateKey);
    }
    if (props.opportunitiesState.candidateInfo == null) {
      setCandidateInfo([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.alternateKey]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] === undefined ||
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  function handleApproveModal() {
    showHideDialog("approveModal", DIALOG.SHOW);
  }

  function handleRejectModal() {
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  const onSubmitApproveDialog = () => {
    const approveFilter = {
      candidateId: candidateId,
      opportunityId: props?.opportunitiesState?.candidateInfo?.opportunityId,
    };

    props.approveOpportunityCandidate(approveFilter, props.opportunitiesState.isOpportunityApplication);
  };

  const onSubmitRejectDialog = (rejectMotiveId, rejectMotiveObservations) => {
    let filter = {
      candidateId,
      opportunityId: props.opportunitiesState?.candidateInfo?.opportunityId,
      rejectMotiveId,
      rejectMotiveObservations,
    };

    props.rejectOpportunityCandidate(filter, props.opportunitiesState.isOpportunityApplication);
  };

  const getTabTitle = () => {
    if (props.opportunitiesState?.candidateInfo?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO)
      return "Candidato a oferta de Emprego";
    if (props.opportunitiesState?.candidateInfo?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL)
      return "Candidato a Estágio Profissional";
    return "";
  };

  return (
    <FormProvider {...methods}>
      <GenericBackdrop open={props.opportunitiesState.candidateInfo_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          {props.opportunitiesState.isOpportunityApplication ? (
            <LocationHeader
              section={"Detalhes do Candidato"}
              subsection={"Lista de Candidaturas"}
              subsectionLink={"/admin/opportunity-applications-management"}
            />
          ) : (
            <LocationHeader
              section={"Detalhes do Candidato"}
              subsection={"Emprego e Estágio Profissional"}
              subsectionLink={"/admin/opportunity-management"}
            />
          )}
        </div>
      )}

      <Container maxWidth={false} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={getTabTitle()} />

        <CandidateGeneralDetails candidateInfo={candidateInfo} />

        <CandidateProfessionalDetails candidateInfo={candidateInfo} />

        <DocumentsSection documents={props.opportunitiesState?.candidateInfo?.opportunityCandidateDocuments} />

        {props.opportunitiesState?.candidateInfo?.opportunityCandidateAnswers?.length > 0 && (
          <CandidateAnswers candidateInfo={candidateInfo} />
        )}

        {props.opportunitiesState?.candidateInfo?.opportunityCandidateStateId === 3 && (
          <CandidateRejectedMotive candidateInfo={candidateInfo} />
        )}

        <Container maxWidth={false}>
          {props.opportunitiesState?.candidateInfo?.opportunityCandidateStateId === 1 && (
            <Grid container>
              <Grid item xs={12} lg={6} xl={8}></Grid>
              <Grid item xs={12} lg={3} xl={2}>
                <FormGroup>
                  <GenericButton typeSubmit={false} color="secondary" onClick={handleRejectModal}>
                    {intl.formatMessage(labels.Label_Reject_Submit)}
                  </GenericButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={3} xl={2}>
                <FormGroup>
                  <GenericButton
                    typeSubmit={false}
                    color="primary"
                    onClick={handleApproveModal}
                    loading={props.opportunitiesState.opportunitySubmitLoading}
                  >
                    {intl.formatMessage(labels.Label_Recruit_Submit)}
                  </GenericButton>
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </Container>
      </Container>

      <ApproveDialog
        name="approve-dialog"
        open={dialogs.approveModal}
        onSubmitApproveDialog={onSubmitApproveDialog}
        title={
          props?.opportunitiesState?.candidateInfo?.papeInternshipOverlapsOthersOfUser
            ? "O período previsto de estágio da oferta atual sobrepõe o de um estágio profissional já atribuído ao candidato. Tem a certeza que pretende recrutar o candidato?"
            : "Confirma que pretende recrutar o candidato?"
        }
        showErrorIcon={props?.opportunitiesState?.candidateInfo?.papeInternshipOverlapsOthersOfUser}
        isLoading={props.opportunitiesState.approveCandidate_loading}
        onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
      />

      <RejectDialog
        name="reject-dialog"
        open={dialogs.rejectModal}
        onSubmitRejectDialog={onSubmitRejectDialog}
        title={"Pretende rejeitar o candidato?"}
        rejectionMotivesList={
          props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] !== undefined
            ? props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]
            : []
        }
        isLoading={props.opportunitiesState.rejectCandidate_loading}
        onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
      />
    </FormProvider>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getCandidateInformation: (userId) => dispatch(getCandidateInformationAction(userId)),
  approveOpportunityCandidate: (formData, isOpportunityApplication) =>
    dispatch(approveOpportunityCandidateAction(formData, isOpportunityApplication)),
  rejectOpportunityCandidate: (formData, isOpportunityApplication) =>
    dispatch(rejectOpportunityCandidateAction(formData, isOpportunityApplication)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetails);
