import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import componentStyles from "assets/theme/views/admin/generic-page-detail.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CounterDisplayField(props) {
  const classes = { ...useStyles(), ...useStylesGeneric() };

  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      {props.isButton ? (
        <Button 
          className={classes.indicatorButton + " " + (props.highlighted ? classes.highlightedIndicatorButton : classes.normalIndicatorButton)}
          onClick={props.handleOnClick}
          data-testid={"counter-display-field-button-" + props.testId}
        > 
          <FormGroup 
            style={props.highlighted && {marginTop: "10px"}}
          >
            <Box className={classes.counterValue}>
              {props.data ? props.data : 0}
            </Box>
            <Box className={classes.counterLabel}>
              {props.label}
              {(props.handleOnClick) && (
                <ArrowForwardIosIcon 
                  style={{width: "12px", height: "12px", marginLeft: "10px", marginTop: "3px"}}
                />
              )}
            </Box> 
          </FormGroup>
        </Button>
      ):(
        <div 
          className={classes.indicatorButton + " " + classes.normalIndicator}
        > 
          <FormGroup 
            style={props.highlighted && {marginTop: "10px"}}
          >
            <Box className={classes.counterValue}>
              {props.data ? props.data : 0}
            </Box>
            <Box className={classes.counterLabel}>
              {props.label}
            </Box> 
          </FormGroup>
        </div>
      )}
    </Grid>
  ); 
}

export default CounterDisplayField;
