import moment from "moment";

export function frontOfficeIndividualUserUpdateMap(formData, documentTypes, userId) {
  //Set FormData
  const data = new FormData();
  if (formData != null && formData !== undefined) {
    const user = {
      id: userId,
      userType: 1,
      name: formData.name ? formData.name : "",
      mobilePhone: formData.mobilePhone ? formData.mobilePhone : "",
      alternativeMobilePhone: formData.alternativeMobilePhone ? formData.alternativeMobilePhone : "",
      email: formData.email ? formData.email : "",
      password: formData.password ? formData.password : "",
      isActive: formData.isActive.code,
    };
    const userSingular = {
      lastName: formData.lastname ? formData.lastname : "",
      dateOfBirth: formData.birthdate ? moment(formData.birthdate).add(1, "hours").format("YYYY-MM-DD") : "",
      identityCard: formData.identityCard ? formData.identityCard : "",
      genderId: formData.gender ? formData.gender.code : 0,
      nationalityId: formData.nationality ? formData.nationality.code : 0,
      maritalStatusId: formData.maritalStatus ? formData.maritalStatus.code : 0,
      niss: formData.niss ? formData.niss : "",
      hasNISS: formData.hasNISS ? formData.hasNISS : "",
      academicLevelId: formData.academicLevel ? formData.academicLevel.code : "",
      degreeId: formData.degree && formData.degree.code !== "" ? formData.degree.code : "",
      professionalCategoryId:
        formData.professionalCategory && formData.professionalCategory.code !== ""
          ? formData.professionalCategory.code
          : "",
      professionalTrainingId: formData.professionalTraining ? formData.professionalTraining.code.toString() : "", // .label?
      entrepreneurshipTrainingId: formData.entrepreneurshipTraining
        ? formData.entrepreneurshipTraining.code.toString()
        : "", // .label?
      smallBusinessMgmtTrainingId: formData.smallBusinessMgmtTraining
        ? formData.smallBusinessMgmtTraining.code.toString()
        : "", // .label?
      experience: formData.experience ? formData.experience : 0,
      specialNeedsId: formData.specialNeeds ? formData.specialNeeds.code : null,
      SpecialNeedsPercent: formData.specialNeedsPercent ?? null,
      isAvailableForInternshipOpportunities: formData.isAvailableForInternshipOpportunities ?? false,
      isAvailableForEmploymentOpportunities: formData.isAvailableForEmploymentOpportunities ?? false,
      familyIncome: formData.familyIncome ?? 0,
      household: formData.household ?? 0,
      intendedWorkplaceProvinceId: formData.intendedWorkplaceProvince ? formData.intendedWorkplaceProvince.code : null,
      professionalQualification: formData.professionalQualification ?? "",
      otherProfessionalExperience: formData.otherProfessionalExperience ?? "",
      secondaryExperience: formData.secondaryExperience ?? 0,
      occupationalCategoryId: formData.occupationalCategory ? formData.occupationalCategory.code : null,
    };
    const compositeAddress = {
      provinceId: formData.province ? formData.province.code : 0,
      municipalityId: formData.municipality ? formData.municipality.code : 0,
      district: formData.district ? formData.district : "",
      address: formData.address ? formData.address : "",
    };

    //Append data
    data.append("id", userId);
    for (const key in user) {
      data.append("user." + key, user[key]);
    }
    for (const key in userSingular) {
      data.append("user.userSingular." + key, userSingular[key]);
    }
    for (const key in compositeAddress) {
      data.append("user.userSingular.compositeAddress." + key, compositeAddress[key]);
    }
    //Lists need to be treated seperately
    formData.professionalExperience?.forEach((element) => {
      data.append("user.userSingular.ProfessionalExperienceList", element.code);
    });
    formData.studiedLanguages?.forEach((element) => {
      data.append("user.userSingular.StudiedLanguagesList", element.code);
    });
    formData.secondaryProfessionalExperience?.forEach((element) => {
      data.append("user.userSingular.SecondaryProfessionalExperienceList", element.code);
    });
    //Append documents
    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (formData[dt.documentTypeId.toString()] && formData[dt.documentTypeId.toString()][0]) {
        data.append("user.UserDocuments[" + countDoc + "].documentTypeId", dt.documentTypeId);
        data.append("user.UserDocuments[" + countDoc + "].documentIndex", countDoc); //we send the index on the files array so the API can identify the document associated with the type
        data.append("UserDocuments", formData[dt.documentTypeId.toString()][0]);
        countDoc++;
      }
    });

    return data;
  }
  return formData;
}

export default frontOfficeIndividualUserUpdateMap;
