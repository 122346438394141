import React from "react";
import { Controller, useForm } from "react-hook-form";

//redux
import { connect } from "react-redux";
//redux-actions
import { DATASET } from "utils/consts";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";

//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import FormSelect from "components/FormFields/FormSelect";
import FormHelperText from "@material-ui/core/FormHelperText";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import { updateGeneralDataMap } from "../../../../mappers/applicationsDetailsMap";
import {
  updateApplicationDetailGeneralDataAction,
  getApplicationDetailsGeneralDataActions,
} from "redux/actions/applicationsAction";
//styles
import componentStyles from "assets/theme/views/admin/generic-page-detail.js";

const useStyles = makeStyles(componentStyles);

function RejectDialog(props) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  //GET MOTIVES
  React.useEffect(() => {
    if (
      props.dataSetState[DATASET.APPLICATION_REJECT_MOTIVES] === undefined ||
      props.dataSetState[DATASET.APPLICATION_REJECT_MOTIVES]?.length === 0
    ) {
      props.getApplicationRejectMotives();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitReject = (data) => {
    props.data["rejectMotiveId"] = data.motive?.code;
    props.data["rejectMotiveObservations"] = data.notes;

    props.updateApplicationDetails(updateGeneralDataMap(props.data), () => {
      props.onClose();
      props.getApplicationDetailsGeneralData(props.data.applicationNumber);
    });
  };

  return (
    <GenericDialog maxWidth={"sm"} open={props.open} onClose={props.onClose}>
      <form onSubmit={handleSubmit(onSubmitReject)}>
        <Box className={classes.helpIconBox}>
          <HelpOutlineIcon className={classes.helpIconRoot} />
        </Box>

        <Container>
          <Box className={classes.modalHeader} marginBottom="30px!important">
            Pretende rejeitar esta candidatura?
          </Box>
          {/* <Box className={classes.subHeader}>
            Ao rejeitar a candidatura, o candidato ficará com a candidatura rejeitada no INEFOP e não irá receber o
            benefício do subprograma a que concorreu. Para prosseguir com a rejeição escolha o motivo, e introduza alguma
            observação que considere pertinente. Se não quiser rejeitar, simplesmente feche a janela.
          </Box> */}
          <Box marginTop="30px">
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Motivo</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="motive"
                    selectOptions={
                      props.dataSetState[DATASET.APPLICATION_REJECT_MOTIVES] !== undefined
                        ? props.dataSetState[DATASET.APPLICATION_REJECT_MOTIVES]
                        : []
                    }
                    setValue={setValue}
                    getValues={getValues}
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["motive"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["motive"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Observações</FormLabel>
                  <Controller
                    name="notes"
                    control={control}
                    component={Box}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={"Escreva aqui as suas observações"}
                        multiline={true}
                        rows={3}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container></Grid>
          </Box>
        </Container>

        <Box textAlign="center" marginBottom="50px" marginTop="50px">
          <GenericButton
            typeSubmit={true}
            color="primary"
            loading={props.applicationsState.application_update_loading}
          >
            Submeter
          </GenericButton>
        </Box>
      </form>
    </GenericDialog>

  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationRejectMotives: () =>
    dispatch(getDataSetByNameAction(DATASET.APPLICATION_REJECT_MOTIVES)),

  updateApplicationDetails: (data, onCloseMethod) =>
    dispatch(updateApplicationDetailGeneralDataAction(data, onCloseMethod)),

  getApplicationDetailsGeneralData: (applicationNum) =>
    dispatch(getApplicationDetailsGeneralDataActions(applicationNum)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectDialog);
