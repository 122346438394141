import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { DATASET, PAGINATION, USER_TYPE } from "utils/consts";
import { getDataSetByNameAction, getDepartmentsAction, clearDataSetByNameAction } from "redux/actions/datasetsAction";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { getFilteredFrontOfficeUsersActions } from "redux/actions/usersAction";
import FormDatePicker from "components/FormFields/FormDatePicker";

import userFilterMap from "mappers/userFilterMap";
import { ArrayUtils } from "utils/array_utils";
import { labels } from "resources/resources";
import { OPERATIONS } from "utils/consts";
import { checkOperationPermission } from "utils/permission";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSectionFo(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const [filter, setFilter] = useState({
    email: "",
    nameOfUser: "",
    numBI: "",
    provinceId: props.authState?.province ?? null,
    municipalityId: null,
    phoneNumber: null,
    typeOfUser: props.match?.params?.typeOfUser ?? null,
    startDate: null,
    endDate: null,
  });

  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  const userTypes = [
    { code: true, label: "Individual" },
    { code: false, label: "Colectivo" },
  ];

  useEffect(() => {
    if (props.match?.params?.typeOfUser === USER_TYPE.COLETIVO.toString()) {
      setValue("typeOfUser", getSelectOptionsElementByCode(userTypes, false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.typeOfUser]);

  const cleanFilter = () => {
    setValue("nameOfUser", "");
    setValue("numBI", "");
    setValue("typeOfUser", undefined);
    setValue("isActive", undefined);
    if (!props.authState?.province) {
      setValue("provinceId", undefined);
    }
    setValue("municipalityId", undefined);
    setValue("email", "");
    setValue("phoneNumber", "");
    setValue("startDate", undefined);
    setValue("endDate", undefined);
    props.clearDataSetByName(DATASET.MUNICIPALITIES);

    setFilter(0);
    setFilterCounter(0);
  };

  useEffect(() => {
    if (ArrayUtils.NullOrEmpty(props.dataSetState[DATASET.PROVINCES])) {
      props.getDataSet(DATASET.PROVINCES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.authState?.province &&
      props.dataSetState[DATASET.PROVINCES] !== undefined &&
      props.dataSetState[DATASET.PROVINCES].length !== 0
    ) {
      var selectedProvince = getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROVINCES],
        props.authState.province
      );
      setValue("provinceId", selectedProvince);
      props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const redirectToCreateFOUserColective = (event) => {
    event.preventDefault();
    history.push("/admin/frontofficeusers-enterprise-create");
  };

  const redirectToCreateFOUserIndividual = (event) => {
    event.preventDefault();
    history.push("/admin/frontofficeusers-individual-create");
  };

  const getFilterSectionName = () => {
    if (props.isAdvancedSearch === true) {
      return (
        <div>
          <div className={classes.sideBySideDiv}>Pesquisa Avançada</div>
          <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
            Filtros {"("}
            {filterCounter}
            {")"}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={classes.sideBySideDiv}>Pesquisa de Utilizadores Frontoffice</div>
          <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
            Filtros {"("}
            {filterCounter}
            {")"}
          </div>
        </div>
      );
    }
  };

  const onSubmit = (data) => {
    let filter = userFilterMap(data);
    filter["pageIndex"] = 1;
    filter["pageSize"] = PAGINATION.PAGE_SIZE;
    setFilter(filter);
    if (props.page === 1) props.getUsers(filter);
    else props.handleChangePage(1);
  };

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.getUsers(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={getFilterSectionName()}
        action={
          <div>
            {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_FRONTOFFICE_ITEM) && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                onClick={redirectToCreateFOUserIndividual}
                style={{ marginRight: "20px" }}
              >
                Criar Utilizador Individual
              </GenericButton>
            )}
            {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_FRONTOFFICE_ITEM) && (
              <GenericButton typeSubmit={false} onClick={redirectToCreateFOUserColective} color="tertiary">
                Criar Utilizador Empresa
              </GenericButton>
            )}
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-nameOfUser-label">Nome</FormLabel>
                  <Controller
                    name="nameOfUser"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        data-testid="filter-section-fo-nameOfUser"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("nameOfUser", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["nameOfUser"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-numBI-label">Nº BI / NIF</FormLabel>
                  <Controller
                    name="numBI"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        data-testid="filter-section-fo-numBI"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("numBI", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["numBI"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-typeOfUser-label">Tipo de Utilizador</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="typeOfUser"
                    getValues={getValues}
                    selectOptions={userTypes}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"typeOfUser"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-isActive-label">
                    Estado
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="isActive"
                    selectOptions={[
                      { code: true, label: "Activo" },
                      { code: false, label: "Inactivo" },
                    ]}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"isActive"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-provinceId-label">Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="provinceId"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={props.authState?.province !== null}
                    loadChildrenDataSet={props.getDataSet}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipalityId"
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"provinceId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-municipalityId-label">Município</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="municipalityId"
                    selectOptions={
                      props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                    }
                    disabled={!getValues("provinceId")}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"municipalityId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-email-label">E-mail</FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        data-testid="filter-section-fo-email"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("email", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["email"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={5} sm={6} md={4} lg={3}>
                <FormGroup>
                  <FormLabel data-testid="filter-section-fo-phoneNumber-label">Telemóvel</FormLabel>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        data-testid="filter-section-fo-phoneNumber"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("phoneNumber", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["phoneNumber"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
            <Grid item xs={7} sm={6} md={4} lg={3}>
              <FormDatePicker
                name="startDate"
                label={"Data de Registo (desde)"}
                control={control}
                getValues={getValues}
                trigger={trigger}
                endDateFieldName="endDate"
                maxDateToday
              />
            </Grid>
            <Grid item xs={7} sm={6} md={4} lg={3}>
              <FormDatePicker
                name="endDate"
                control={control}
                label={"Data de Registo (até)"}
                getValues={getValues}
                trigger={trigger}
                startDateFieldName="startDate"
                maxDateToday
              />
            </Grid>
          </Grid>

            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.usersState.frontoffice_users_search_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getDepartments: (name) => dispatch(getDepartmentsAction(name)),
  getUsers: (name) => dispatch(getFilteredFrontOfficeUsersActions(name)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterSectionFo));
