//compile the other user actions into this one!

import {
  getFilteredBackOfficeUsers,
  getFilteredFrontOfficeUsers,
  getUserInfo,
  getUserInfoBo,
  getUserDocument,
  updateProfile,
  frontOfficeIndividualUserUpdate,
  backOfficeUserUpdate,
  validateColectiveUser,
  getFoUserColectiveBasicInfo,
  register,
  searchFOUsers,
  getFOUserOverview,
  addFOUserHistoryEntry,
  getFOUserHistory,
  getFOUserEmploymentHistory,
  getFOUserUnemploymentHistory,
  createFOUserUnemploymentHistory,
  updateFOUserUnemploymentHistory,
  createFOUserEmploymentHistory,
  updateFOUserEmploymentHistory,
  getFoUSInterventionOtherArea,
  createFoUSInterventionOtherArea,
  updateFoUSInterventionOtherArea,
} from "crud/users.crud";
import { USERS, DIALOG } from "utils/actionsConsts";
import { b64ToImage, convertByteArrayToBlobUrl } from "utils/converters";
import { getStringFromList } from "utils/formSelectMultiple";

export const registerAction = (user) => async (dispatch) => {
  dispatch({
    type: USERS.REGISTER_FO_USER_LOADING,
    payload: true,
  });
  register(user)
    .then((res) => {
      dispatch({
        type: USERS.REGISTER_FO_USER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            goBack: res.data,
            onClose: "/admin/frontofficesers-management",
            message: res.data
              ? "Registo efectuado com sucesso"
              : "Erro a efectuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.REGISTER_FO_USER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            noRedirect: true,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
    });
};

export const updateProfileAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.UPDATE_Profile_LOADING,
    payload: true,
  });
  updateProfile(formData)
    .then((res) => {
      if (res.data) {
        dispatch({
          type: USERS.UPDATE_Profile_LOADING,
          payload: false,
        });
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: true,
              message: "Perfil actualizado com sucesso",
            },
          },
        });
        dispatch({
          type: "updateProfile",
          payload: {
            name: formData.name,
            lastName: formData.surname,
            photo: b64ToImage(formData.photo),
          },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Erro a atualizar o seu perfil, por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_Profile_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFilteredFrontOfficeUsersActions = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.GET_FRONTOFFICE_FILTERED_SEARCH_LOADING,
    payload: true,
  });
  getFilteredFrontOfficeUsers(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          nameOfuser: record.nameOfuser,
          surname: record.address,
          numBI: record.numBI,
          province: record.province,
          municipality: record.municipality,
          userType: record.userType === 2 ? "Colectivo" : "Individual",
          phone: record.phone,
          email: record.email,
          isActive: record.isActive,
          id: record.id,
        };
      });

      dispatch({
        type: USERS.GET_FRONTOFFICE_FILTERED_SEARCH,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: USERS.GET_FRONTOFFICE_FILTERED_SEARCH_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FRONTOFFICE_FILTERED_SEARCH,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.GET_FRONTOFFICE_FILTERED_SEARCH_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFilteredBackOfficeUsersActions = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.GET_BACKOFFICE_FILTERED_SEARCH_LOADING,
    payload: true,
  });
  console.log(formData);
  getFilteredBackOfficeUsers(formData)
    .then((res) => {
      console.log(res.data);
      const mappedList = res.data?.data?.map((record) => {
        return {
          id: record.id,
          nameOfuser: record.name,
          surname: record.surname,
          numBI: record.email,
          provinceId: record.province,
          municipalityId: record.municipality,
          provinceName: record.provinceName,
          municipalityName: record.municipalityName,
          email: record.email,
          profile: record.profile ? getStringFromList(record.profile, "; ") : "", //função?
          active: record.active,
        };
      });
      dispatch({
        type: USERS.GET_BACKOFFICE_FILTERED_SEARCH,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: USERS.GET_BACKOFFICE_FILTERED_SEARCH_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_BACKOFFICE_FILTERED_SEARCH,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.GET_BACKOFFICE_FILTERED_SEARCH_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getUserInfoAction = (id) => async (dispatch) => {
  dispatch({
    type: USERS.GET_USER_INFO_LOADING,
    payload: true,
  });
  dispatch({
    type: USERS.GET_USER_INFO,
    result: true,
    payload: {},
  });
  getUserInfo(id)
    .then((res) => {
      const record = res.data;
      const userInfo = {
        id: record.id ? record.id : "",
        userType: record.userType ? record.userType : "",
        name: record.name ? record.name : "",
        mobilePhone: record.mobilePhone ? record.mobilePhone : "",
        alternativeMobilePhone: record.alternativeMobilePhone ? record.alternativeMobilePhone : "",
        email: record.email ? record.email : "",
        userCode: record.userCode ? record.userCode : "",
        userDocuments: record.userDocuments,
        userTypeDocuments: record.userTypeDocuments,
        isActive: record.isActive,
        userSingular: {
          lastName: record.userSingular.lastName ? record.userSingular.lastName : "",
          dateOfBirth: record.userSingular.dateOfBirth ? record.userSingular.dateOfBirth : "",
          identityCard: record.userSingular.identityCard ? record.userSingular.identityCard : "",
          specialNeedsId: record.userSingular.specialNeedsId ?? "",
          specialNeedsPercent: record.userSingular.specialNeedsPercent ?? "",
          gender: record.userSingular.genderId ? record.userSingular.genderId : "",
          maritalStatus: record.userSingular.maritalStatusId ? record.userSingular.maritalStatusId : "",
          nationality: record.userSingular.nationalityId ? record.userSingular.nationalityId : "",
          niss: record.userSingular.niss ? record.userSingular.niss : "",
          hasNISS: record.userSingular.hasNISS ? record.userSingular.hasNISS : "",
          canModifyIdentityCard: record.userSingular.canModifyIdentityCard,
          compositeAddress: {
            province: record.userSingular.compositeAddress.provinceId
              ? record.userSingular.compositeAddress.provinceId
              : "",
            municipality: record.userSingular.compositeAddress.municipalityId
              ? record.userSingular.compositeAddress.municipalityId
              : "",
            district: record.userSingular.compositeAddress.district
              ? record.userSingular.compositeAddress.district
              : "",
            address: record.userSingular.compositeAddress.address ? record.userSingular.compositeAddress.address : "",
          },
          academicLevel: record.userSingular.academicLevelId ? record.userSingular.academicLevelId : "",
          degree: record.userSingular.degreeId ? record.userSingular.degreeId : "",
          professionalCategory: record.userSingular.professionalCategoryId
            ? record.userSingular.professionalCategoryId
            : "",
          professionalTraining: record.userSingular.professionalTrainingId
            ? record.userSingular.professionalTrainingId
            : "",
          entrepreneurshipTraining: record.userSingular.entrepreneurshipTrainingId
            ? record.userSingular.entrepreneurshipTrainingId
            : "",
          smallBusinessMgmtTraining: record.userSingular.smallBusinessMgmtTrainingId
            ? record.userSingular.smallBusinessMgmtTrainingId
            : "",
          professionalExperience: record.userSingular.professionalExperienceList
            ? record.userSingular.professionalExperienceList
            : "",
          experience: record.userSingular.experience ? record.userSingular.experience : "",
          isAvailableForInternshipOpportunities: record.userSingular.isAvailableForInternshipOpportunities ?? false,
          isAvailableForEmploymentOpportunities: record.userSingular.isAvailableForEmploymentOpportunities ?? false,
          familyIncome: record.userSingular.familyIncome ?? 0,
          household: record.userSingular.household ?? 0,
          intendedWorkplaceProvinceId: record.userSingular.intendedWorkplaceProvinceId,
          professionalQualification: record.userSingular.professionalQualification ?? "",
          otherProfessionalExperience: record.userSingular.otherProfessionalExperience ?? "",
          secondaryExperience: record.userSingular.secondaryExperience ?? 0,
          occupationalCategoryId: record.userSingular.occupationalCategoryId,
          studiedLanguagesList: record.userSingular.studiedLanguagesList,
          secondaryProfessionalExperienceList: record.userSingular.secondaryProfessionalExperienceList,
        },
      };
      dispatch({
        type: USERS.GET_USER_INFO,
        result: true,
        payload: userInfo,
      });
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_USER_INFO,
        result: false,
        payload: undefined,
      });
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getEnterpriseUserInfoAction = (id) => async (dispatch) => {
  dispatch({
    type: USERS.GET_USER_INFO_LOADING,
    payload: true,
  });
  getUserInfo(id)
    .then((res) => {
      const record = res.data;
      const userInfo = {
        id: record.id ? record.id : "",
        userType: record.userType ? record.userType : "",
        name: record.name ? record.name : "",
        mobilePhone: record.mobilePhone ? record.mobilePhone : "",
        alternativeMobilePhone: record.alternativeMobilePhone ? record.alternativeMobilePhone : "",
        email: record.email ? record.email : "",
        userCode: record.userCode ? record.userCode : "",
        isActive: record.isActive,
        userColective: {
          contactName: record.userColective.contactName ? record.userColective.contactName : "",
          businessPurpose: record.userColective.businessPurposeList ? record.userColective.businessPurposeList : "",
          taxIdNumber: record.userColective.taxIdNumber ? record.userColective.taxIdNumber : 0,
          permitCode: record.userColective.permitCode ? record.userColective.permitCode : 0,
          permitCodeValidUntil: record.userColective.permitCodeValidUntil
            ? record.userColective.permitCodeValidUntil
            : "",
          NISS: record.userColective.niss ? record.userColective.niss : "",
          experience: record.userColective.experience ? record.userColective.experience : 0,
          companyEmail: record.userColective.companyEmail ? record.userColective.companyEmail : "",
          companyPhone: record.userColective.companyPhone ? record.userColective.companyPhone : "",
          compositeAddress: {
            province: record.userColective.compositeAddress.provinceId
              ? record.userColective.compositeAddress.provinceId
              : 0,
            municipality: record.userColective.compositeAddress.municipalityId
              ? record.userColective.compositeAddress.municipalityId
              : 0,
            district: record.userColective.compositeAddress.district
              ? record.userColective.compositeAddress.district
              : "",
            address: record.userColective.compositeAddress.address ? record.userColective.compositeAddress.address : "",
          },
          approved: record.userColective.approved,
          companyEACId: record.userColective.companyEACId ?? "",
          propertyType: record.userColective.propertyType ?? "",
          subsidiaryCompany: record.userColective.subsidiaryCompany ?? "",
          subsidiaryCompanyPostalCode: record.userColective.subsidiaryCompanyPostalCode ?? "",
          subsidiaryCompanyActivity: record.userColective.subsidiaryCompanyActivity ?? "",
          subsidiaryCompanyEACId: record.userColective.subsidiaryCompanyEACId ?? "",
          maleWorkersNumber: record.userColective.maleWorkersNumber ?? "",
          femaleWorkersNumber: record.userColective.femaleWorkersNumber ?? "",
        },
      };
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });
      dispatch({
        type: USERS.GET_USER_INFO,
        result: true,
        payload: userInfo,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_USER_INFO,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getBackOfficeUserInfoAction = (id) => async (dispatch) => {
  dispatch({
    type: USERS.GET_USER_INFO_BO_LOADING,
    payload: true,
  });
  getUserInfoBo(id)
    .then((res) => {
      const mappedList = {
        active: res.data.active,
        email: res.data.email,
        id: res.data.id,
        profile: res.data.profile,
        surname: res.data.surname,
        province: res.data.province,
        municipality: res.data.municipality,
        name: res.data.name,
        roles: res.data.roles,
        isEditable: res.data.isEditable,
        managerBankId: res.data.managerBankId,
        managerLoanTypes: res.data.managerLoanTypes,
        employmentCenterId: res.data.employmentCenterId,
      };
      dispatch({
        type: USERS.GET_USER_INFO_BO,
        result: true,
        payload: mappedList,
      });
      dispatch({
        type: USERS.GET_USER_INFO_BO_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_USER_INFO_BO,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.GET_USER_INFO_BO_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getUserDocumentAction = (docId, docName) => async (dispatch) => {
  getUserDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_USER_DOCUMENT_DATA_DOWNLOAD,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

export const updateEnterpriseUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.UPDATE_ENTERPRISE_USER_LOADING,
    payload: true,
  });
  frontOfficeIndividualUserUpdate(formData)
    .then((res) => {
      dispatch({
        type: USERS.UPDATE_ENTERPRISE_USER,
        payload: res.data,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            message: "Utilizador actualizado com sucesso",
            goBack: res.data,
            onClose: "/admin/frontofficeusers-management",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_ENTERPRISE_USER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_ENTERPRISE_USER,
        payload: false,
      });
      dispatch({
        type: USERS.UPDATE_ENTERPRISE_USER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateIndividualUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.UPDATE_INDIVIDUAL_USER_LOADING,
    payload: true,
  });
  frontOfficeIndividualUserUpdate(formData)
    .then((res) => {
      dispatch({
        type: USERS.UPDATE_INDIVIDUAL_USER,
        payload: res.data,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            message: "Utilizador actualizado com sucesso",
            goBack: res.data,
            onClose: "/admin/frontofficeusers-management",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_INDIVIDUAL_USER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_INDIVIDUAL_USER,
        payload: false,
      });
      dispatch({
        type: USERS.UPDATE_INDIVIDUAL_USER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateBoUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.UPDATE_BO_USER_LOADING,
    payload: true,
  });
  backOfficeUserUpdate(formData)
    .then((res) => {
      dispatch({
        type: USERS.UPDATE_BO_USER,
        payload: res.data,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            message: "Utilizador actualizado com sucesso",
            goBack: true,
            onClose: "/admin/backOfficeUsers-management",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_BO_USER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: USERS.UPDATE_BO_USER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const validateColectiveUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.VALIDATE_COLECTIVE_USER_LOADING,
    payload: true,
  });
  validateColectiveUser(formData)
    .then((res) => {
      dispatch({
        type: USERS.VALIDATE_COLECTIVE_USER,
        payload: res.data,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            message: res.data ? "Utilizador validado com sucesso." : "Ocorreu um erro a validar o utilizador.",
            goBack: true,
            onClose: "/admin/frontofficeusers-management",
          },
        },
      });
      dispatch({
        type: USERS.VALIDATE_COLECTIVE_USER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: USERS.VALIDATE_COLECTIVE_USER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFoUserColectiveBasicInfoAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.GET_USER_INFO_LOADING,
    payload: true,
  });
  getFoUserColectiveBasicInfo(formData)
    .then((res) => {
      const record = res.data;
      const userInfo = {
        id: record.id ? record.id : "",
        name: record.name ? record.name : "",
        taxIdNumber: record.taxIdNumber ? record.taxIdNumber : "",
        niss: record.niss ? record.niss : "",
        contactName: record.contactName ? record.contactName : "",
      };
      dispatch({
        type: USERS.GET_FO_USER_COLECTIVE_BASIC_INFO,
        result: true,
        payload: userInfo,
      });
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FO_USER_COLECTIVE_BASIC_INFO,
        result: false,
        payload: {},
      });
      dispatch({
        type: USERS.GET_USER_INFO_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "A empresa indicada não existe ou não está validada",
          },
        },
      });
      console.log("error", error);
    });
};

export const searchFOUsersAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.SEARCH_FO_USERS_LOADING,
    payload: true,
  });
  searchFOUsers(formData)
    .then((res) => {
      dispatch({
        type: USERS.SEARCH_FO_USERS,
        result: true,
        payload: {
          data: res.data,
        },
      });
      dispatch({
        type: USERS.SEARCH_FO_USERS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.SEARCH_FO_USERS,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.SEARCH_FO_USERS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFOUserOverviewAction = (formData) => async (dispatch) => {
  dispatch({
    type: USERS.GET_FO_USER_OVERVIEW_LOADING,
    payload: true,
  });
  getFOUserOverview(formData)
    .then((res) => {
      dispatch({
        type: USERS.GET_FO_USER_OVERVIEW,
        result: true,
        payload: res.data,
      });
      dispatch({
        type: USERS.GET_FO_USER_OVERVIEW_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FO_USER_OVERVIEW,
        result: false,
        payload: [],
      });
      dispatch({
        type: USERS.GET_FO_USER_OVERVIEW_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const addFOUserHistoryEntryAction = (formData) => async (dispatch) => {
  addFOUserHistoryEntry(formData)
    .then((res) => {
      dispatch({
        type: USERS.CREATE_FO_USER_HISTORY_ENTRY,
        payload: res.data,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            goBack: false,
            isSuccess: res.data,
            message: res.data ? "Comentário adicionado com sucesso" : "Ocorreu a adicionar comentário",
          },
        },
      });
      dispatch({
        type: USERS.GET_FO_USER_HISTORY_REFRESH_RESULTS,
        payload: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.CREATE_FO_USER_HISTORY_ENTRY,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getFOUserHistoryActions = (formData) => async (dispatch) => {
  getFOUserHistory(formData)
    .then((res) => {
      dispatch({
        type: USERS.GET_FO_USER_HISTORY_REFRESH_RESULTS,
        payload: false,
      });
      dispatch({
        type: USERS.GET_FO_USER_HISTORY,
        result: true,
        payload: res.data.map((record) => {
          return {
            id: record.id,
            date: record.date,
            title: record.title,
            description: record.description,
            applicationCode: record.applicationCode,
            historyEntryTypeKeyValue: record.historyEntryTypeKeyValue,
          };
        }),
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FO_USER_HISTORY_REFRESH_RESULTS,
        payload: false,
      });
      dispatch({
        type: USERS.GET_FO_USER_HISTORY,
        result: false,
        payload: [],
      });

      console.log("error", error);
    });
};

export const getFOUserEmploymentHistoryAction = (data) => async (dispatch) => {
  dispatch({
    type: USERS.GET_FO_USER_EMPLOYMENT_HISTORY_LOADING,
    payload: true,
  });
  getFOUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: USERS.GET_FO_USER_EMPLOYMENT_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FO_USER_EMPLOYMENT_HISTORY,
        payload: {},
      });
      dispatch({
        type: USERS.GET_FO_USER_EMPLOYMENT_HISTORY_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFOUserUnemploymentHistoryAction = (data) => async (dispatch) => {
  dispatch({
    type: USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY_LOADING,
    payload: true,
  });

  getFOUserUnemploymentHistory(data)
    .then((res) => {
      dispatch({
        type: USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY,
        payload: {},
      });
      dispatch({
        type: USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const createFOUserUnemploymentHistoryAction = (formData, onCloseAction) => async (dispatch) => {
  return createFOUserUnemploymentHistory(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data.succeeded ? onCloseAction : undefined,
            okAction: res.data.succeeded ? onCloseAction : undefined,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Registo de desemprego criado com sucesso"
              : "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateFOUserUnemploymentHistoryAction = (formData, onCloseAction) => async (dispatch) => {
  return updateFOUserUnemploymentHistory(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data.succeeded ? onCloseAction : undefined,
            okAction: res.data.succeeded ? onCloseAction : undefined,
            isSuccess: res.data.succeeded,
            message: res.data.succeeded
              ? "Registo de desemprego actualizado com sucesso"
              : "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const updateFOUserEmploymentHistoryAction = (data, onCloseAction) => async (dispatch) => {
  return updateFOUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? onCloseAction : undefined,
            okAction: res.data ? onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Registo de emprego actualizado com sucesso"
              : "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const createFOUserEmploymentHistoryAction = (data, onCloseAction) => async (dispatch) => {
  return createFOUserEmploymentHistory(data)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? onCloseAction : undefined,
            okAction: res.data ? onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Registo de emprego criado com sucesso"
              : "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const getFoUSInterventionOtherAreaAction = (data) => async (dispatch) => {
  dispatch({
    type: USERS.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING,
    payload: true,
  });
  getFoUSInterventionOtherArea(data)
    .then((res) => {
      dispatch({
        type: USERS.GET_FOUS_INTERVENTION_OTHER_AREA,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: USERS.GET_FOUS_INTERVENTION_OTHER_AREA,
        payload: {},
      });
      dispatch({
        type: USERS.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const createFoUSInterventionOtherAreaAction = (data, onCloseAction) => async (dispatch) => {
  return createFoUSInterventionOtherArea(data)
    .then(({ data: response }) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: response ? onCloseAction : undefined,
            okAction: response ? onCloseAction : undefined,
            isSuccess: response,
            message: response
              ? "Registo criado com sucesso"
              : "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    })
    .catch(() => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao criar o registo, por favor tente mais tarde.",
          },
        },
      });
    });
};

export const updateFoUSInterventionOtherAreaAction = (data, onCloseAction) => async (dispatch) => {
  return updateFoUSInterventionOtherArea(data)
    .then(({ data: response }) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: response ? onCloseAction : undefined,
            okAction: response ? onCloseAction : undefined,
            isSuccess: response,
            message: response
              ? "Registo actualizado com sucesso"
              : "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });

    })
    .catch(() => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar o registo, por favor tente mais tarde.",
          },
        },
      });

    });
};

export default getFilteredFrontOfficeUsersActions;
