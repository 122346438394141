import axios from "axios";
import { INTERNAL_API_URL, EXTERNAL_API_URL } from "../index";

export const GET_FILTERED_OPPORTUNITY_URL = "opportunity/get-filtered-opportunities";
export const GET_OPPORTUNITIES_DETAILS_URL = "opportunity/get-opportunity-details";
export const POST_UPDATE_OPPORTUNITY_STATE_URL = "opportunity/update-opportunity-state";
export const GET_USER_INFO_URL = "opportunity/get-opportunity-user-info";
export const GET_OPPORTUNITY_USERS_URL = "opportunity/get-opportunity-users";
export const UPDATE_OPPORTUNITY_URL = "opportunity/update";
export const CREATE_OPPORTUNITY_URL = "opportunity/create";
export const APPROVE_CANDIDATE_URL = "opportunity/approve-opportunity-candidate";
export const REJECT_CANDIDATE_URL = "opportunity/reject-opportunity-candidate";
export const GET_OPPORTUNITY_DOCUMENT = "opportunity/get-opportunity-document";
export const GET_POSSIBLE_OPPORTUNITY_CANDIDATES = "opportunity/get-possible-opportunity-candidates";
export const ADD_OPPORTUNITY_CANDIDATES_LIST = "opportunity/add-opportunity-candidates-list";
export const GET_ALL_OPPORTUNITY_APPLICATIONS_URL = "opportunity/get-all-opportunity-applications";
export const GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_URL = "opportunity/get-all-opportunity-applications-followup";
export const GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_URL = "opportunity/get-opportunity-application-followup-details";
export const UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD = "opportunity/update-opportunity-application-followup-period";
export const GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_FEEDBACK_REPORT_URL = "opportunity/get-opportunity-application-followup-feedback-report-details";
export const VALIDATE_FEEDBACK_REPORT_URL = "opportunity/validate-feedback-report";
export const CREATE_ADHOC_FEEDBACK_REPORT_URL = "opportunity/create-adhoc-feedback-report";
export const GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_URL = "opportunity/get-available-opportunities-for-user";
export const CREATE_APPLICATIONS_FOR_USER_URL = "opportunity/create-opportunity-applications-for-user";

export function getFilteredOpportunities(data) {
  return axios.get(INTERNAL_API_URL + GET_FILTERED_OPPORTUNITY_URL, {
    params: data,
  });
}

export function getOpportunitiesDetails(_opportunityId) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITIES_DETAILS_URL, {
    params: {
      opportunityId: _opportunityId,
    },
  });
}

export function createOpportunity(opportunity) {
  return axios.post(EXTERNAL_API_URL + CREATE_OPPORTUNITY_URL, opportunity);
}

export function updateOpportunity(opportunity) {
  return axios.post(EXTERNAL_API_URL + UPDATE_OPPORTUNITY_URL, opportunity);
}

export function updateOpportunityState(formData) {
  return axios.post(EXTERNAL_API_URL + POST_UPDATE_OPPORTUNITY_STATE_URL, formData);
}

export function getCandidateInformation(userId) {
  return axios.get(EXTERNAL_API_URL + GET_USER_INFO_URL, {
    params: {
      alternateKey: userId,
    },
  });
}

export function getOpportunityUsers(formData) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_USERS_URL, {
    params: formData,
  });
}

export function getOpportunityDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_DOCUMENT, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function approveOpportunityCandidate(formData) {
  return axios.post(EXTERNAL_API_URL + APPROVE_CANDIDATE_URL, formData);
}

export function rejectOpportunityCandidate(formData) {
  return axios.post(EXTERNAL_API_URL + REJECT_CANDIDATE_URL, formData);
}

export function getFilteredPossibleOpportunityCandidates(data) {
  return axios.get(EXTERNAL_API_URL + GET_POSSIBLE_OPPORTUNITY_CANDIDATES, {
    params: data,
  });
}

export function addOpportunityCandidatesList(formData) {
  return axios.post(EXTERNAL_API_URL + ADD_OPPORTUNITY_CANDIDATES_LIST, formData);
}

export function getAllOpportunityApplications(formData) {
  return axios.get(INTERNAL_API_URL + GET_ALL_OPPORTUNITY_APPLICATIONS_URL, {
    params: formData,
  });
}

export function getAllOpportunityApplicationsFollowUp(formData) {
  return axios.get(INTERNAL_API_URL + GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_URL, {
    params: formData,
  });
}

export function getOpportunityApplicationsFollowUpDetails(candidateCode) {
  return axios.get(INTERNAL_API_URL + GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_URL, {
    params: {
      opportunityCandidateId: candidateCode,
    },
  });
}

export function updateOpportunityFollowupPeriod(formData) {
  return axios.post(
    EXTERNAL_API_URL + UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD,
    formData
  );
}

export function getOpportunityApplicationsFollowUpFeedbackReport(feedbackReportId) {
  return axios.get(EXTERNAL_API_URL + GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_FEEDBACK_REPORT_URL, {
    params: {
      feedbackReportId: feedbackReportId,
    },
  });
}

export function validateFeedbackReport(formData) {
  return axios.post(
    INTERNAL_API_URL + VALIDATE_FEEDBACK_REPORT_URL,
    formData
  );
}

export function createAdHocFeedbackReport(formData) {
  return axios.post(
    INTERNAL_API_URL + CREATE_ADHOC_FEEDBACK_REPORT_URL,
    formData
  );
}

export function getFilteredAvailableOpportunitiesForUser(formData) {
  return axios.post(
    INTERNAL_API_URL + GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_URL, 
    formData
  );
}

export function createApplicationsForUser(formData) {
  return axios.post(
    INTERNAL_API_URL + CREATE_APPLICATIONS_FOR_USER_URL,
    formData
  );
}