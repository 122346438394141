import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles, labels, tooltips } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{ title: classes.cardHeader }}
          title={intl.formatMessage(titles.Title_UserOverview_GeneralDataTab_GeneralDataSection)}
          data-testid="user-overview-general-data-section-title"
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField
              xs={12}
              sm={12}
              md={12}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanyName)}
              data={props?.userGeneralDataOverview?.name}
              id="companyName"
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_TaxIdNumber)}
              data={props?.userGeneralDataOverview?.taxIdNumber}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCode)}
              data={props?.userGeneralDataOverview?.permitCode}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCodeExpirationDate
              )}
              data={
                props?.userGeneralDataOverview?.permitCodeExpirationDate
                  ? moment(props?.userGeneralDataOverview?.permitCodeExpirationDate).format("DD/MM/YYYY")
                  : ""
              }
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Province)}
              data={props?.userGeneralDataOverview?.provinceDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Municipality)}
              data={props?.userGeneralDataOverview?.municipalityDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_District)}
              data={props?.userGeneralDataOverview?.district}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_Address)}
              data={props?.userGeneralDataOverview?.address}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={
                <>
                  {intl.formatMessage(
                    labels.Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanySocialSecurityNumber
                  )}
                  <Tooltip title={intl.formatMessage(tooltips.Tooltip_Social_Security_Number_Description)}>
                    <HelpIcon className={classes.tooltipInfoIcon} />
                  </Tooltip>
                </>
              }
              data={props?.userGeneralDataOverview?.niss}
              id="niss"
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_YearsOfExperience
              )}
              data={props?.userGeneralDataOverview?.yearsOfExperience}
            />
            <DisplayField
              xs={12}
              sm={props?.userGeneralDataOverview?.technicalCapabilityDescriptions?.length > 6 ? 12 : 6}
              md={props?.userGeneralDataOverview?.technicalCapabilityDescriptions?.length > 6 ? 8 : 4}
              xl={props?.userGeneralDataOverview?.technicalCapabilityDescriptions?.length > 6 ? 6 : 3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_BusinessPurpose
              )}
              data={props?.userGeneralDataOverview?.businessPurposeDescriptions?.join(", ")}
              id="businessPurpose"
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_CompanyEACDescription
              )}
              data={props?.userGeneralDataOverview?.companyEACDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_PropertyType)}
              data={props?.userGeneralDataOverview?.propertyType}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompany
              )}
              data={props?.userGeneralDataOverview?.subsidiaryCompany}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyPostalCode
              )}
              data={props?.userGeneralDataOverview?.subsidiaryCompanyPostalCode}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyActivity
              )}
              data={props?.userGeneralDataOverview?.subsidiaryCompanyActivity}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyEACDescription
              )}
              data={props?.userGeneralDataOverview?.subsidiaryCompanyEACDescription}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_MaleWorkersNumber
              )}
              data={props?.userGeneralDataOverview?.maleWorkersNumber}
            />
            <DisplayField
              xs={12}
              sm={6}
              md={4}
              xl={3}
              label={intl.formatMessage(
                labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_FemaleWorkersNumber
              )}
              data={props?.userGeneralDataOverview?.femaleWorkersNumber}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(GeneralDataSection);
