import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import moment from "moment";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import DetailDataTab from "./Tabs/OpportunityDetailsTab";
import CandidatesTab from "../OpportunityCandidates/Tabs/OpportunityCandidatesTab";
import { getOpportunitiesDetailsAction } from "redux/actions/opportunitiesAction";
import { OPPORTUNITY_STATE_KEY } from "utils/consts";
import useScrollDirection from "hooks/useScrollDirection";
import { alerts } from "resources/resources";
import Tabs from "components/Tabs/Tabs";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityDetails(props) {
  const classes = useStylesGeneric();
  const scrollDirection = useScrollDirection();
  const intl = useIntl();

  const TABS = {
    DETAILS_DATA: "detailDataTab",
    CANDIDATES: "candidatesTab",
  };

  const [tabStep, setTabStep] = useState(TABS.DETAILS_DATA);

  useEffect(() => {
    if (
      props.opportunitiesState.opportunityDetailsData === undefined ||
      props.opportunitiesState.opportunityDetailsData.opportunityId !== props.match.params.opportunityId
    ) {
      props.getOpportunityDetails(props.match.params.opportunityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.opportunityId]);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case TABS.DETAILS_DATA:
        return (
          <DetailDataTab
            opportunityId={props.match.params.opportunityId}
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
          />
        );
      case TABS.CANDIDATES:
        return (
          <CandidatesTab
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
            opportunityId={props.match.params.opportunityId}
          />
        );
      default:
        return (
          <DetailDataTab
            opportunityId={props.match.params.opportunityId}
            opportunityDetailsData={props.opportunitiesState.opportunityDetailsData}
          />
        );
    }
  }
  return (
    <>
      <GenericBackdrop open={props.opportunitiesState.get_opportunity_details_loading} />
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            subsection={"Emprego e Estágio Profissional"}
            section={"Detalhes da Oferta"}
            subsectionLink={"/admin/opportunity-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <PageTitleHeader title={props.opportunitiesState.opportunityDetailsData?.title} />
          </Grid>
          {props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA && (
            <Grid item xs={12} lg={6}>
              {moment().isAfter(moment(props.opportunitiesState?.opportunityDetailsData?.validTo).add(1, "days")) && (
                <Alert
                  severity="info"
                  style={{ marginRight: "24px", marginLeft: "24px" }}
                  classes={{
                    icon: classes.alertIcon,
                  }}
                >
                  {intl.formatMessage(alerts.Alert_Cannot_Add_Candidates_To_Opportunity_Because_Passed_ValidTo_Date)}
                </Alert>
              )}

              {moment().isBefore(
                moment(props.opportunitiesState?.opportunityDetailsData?.validFrom).add(1, "days")
              ) && (
                <Alert
                  severity="info"
                  style={{ marginRight: "24px", marginLeft: "24px" }}
                  classes={{
                    icon: classes.alertIcon,
                  }}
                >
                  {intl.formatMessage(alerts.Alert_Cannot_Add_Candidates_To_Opportunity_Because_Before_ValidFrom_Date)}
                </Alert>
              )}
            </Grid>
          )}
        </Grid>

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.DETAILS_DATA,
                testId: "opportunity-detail-details-tab",
                text: "Dados Gerais",
              },
              {
                value: TABS.CANDIDATES,
                renderIf:
                  props.opportunitiesState.opportunityDetailsData?.opportunityStateKey ===
                    OPPORTUNITY_STATE_KEY.APROVADA ||
                  (props.opportunitiesState.opportunityDetailsData?.opportunityStateKey ===
                    OPPORTUNITY_STATE_KEY.FECHADA &&
                    props.opportunitiesState.opportunityDetailsData?.opportunityCandidates.length > 0),
                testId: "opportunity-detail-candidates-tab",
                text: "Candidatos",
              },
            ]}
            onChange={handleTabStep}
            step={tabStep}
          />

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getOpportunityDetails: (opportunityId) => dispatch(getOpportunitiesDetailsAction(opportunityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
