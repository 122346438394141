import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardHeader: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  cardHeaderAvatar: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "17px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.primary.main,

  },
  cardHeaderTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: theme.palette.gray[700],
  },
  cardHeaderIcon: {
    width: "20px",
    height: "auto",
  },
  cardHeaderAction: {
    display: "flex",
  },
  cardHeaderActionGrid: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  modalHeader: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "33px",
    textAlign: "center",
    color: theme.palette.gray[700],
    marginBottom: "65px",
  },
  subHeader: {
    fontStyle: "normal",
    fontSize: "1rem",
    alignItems: "center",
    textAlign: "center",
    marginTop:"-40px"    
  },
  modalApproveLoan: {
    // maxWidth: "530px",
    // height: "571px",
    // position: "absolute",
    // marginLeft: "25.07%",
    // marginRight: "25.07%",
    // marginBottom: "31.35%",
  },
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    marginTop: "10rem",
  },
  timelineDot: {
    color: theme.palette.primary.greyBlue,
  },
  timelineIcons: {
    color: theme.palette.white.main,
    width: "25px",
    height: "auto",
    marginTop: "5px",
  },
  timelineIconsBox: {
    background: theme.palette.gray[700], 
    borderRadius: "35px",
    width: "37px",
    height: "37px",
    alignItems: "center",
    textAlign: "center",
  },
  navigateBefore: {
    color: theme.palette.primary.main,
    width: "30px",
    height: "auto",
    marginTop: "5px",
  },
  navigateBeforeBox: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "34px",
    width: "43px",
    height: "43px",
    alignItems: "center",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:hover, &:focus": {
      boxShadow: "0px 0px 10px orange !important",
    },
  },
  titleGrid: {
    display: "flex",
  },
  ellipse: {
    marginLeft: "120px",
    marginTop: "-2px",
    width: "38px",
    height: "38px",
  },
  vector: {
    marginTop: "0px",
    marginLeft: "-23px",
    width: "7px",
    height: "14px",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginLeft: "22px",
  },
  alignRight: {
    textAlign: "right",
  },
  mb0: {
    marginBottom: "0!important",
  },
  containerPadding: {
    marginBottom: "37px",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
  },
  borderWarning: {
    borderColor: theme.palette.warning.main + "!important",
  },
  selectBorderWarningInputRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.warning.main + "!important",
    },    
  },
  monetaryFields: {
    textAlign: "end",
  },
  saveButton: {
    background: theme.palette.primary.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "50%",
  },
  saveProfileButton: {
    background:
      "linear-gradient(90deg, " +
      theme.palette.info.main +
      " 0%, " +
      theme.palette.primary.mediumBlue +
      " 100%)",
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.white.main,
    width: "62%",
  },
  cancelButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    // color: theme.palette.primary.main,
    width: "50%",
    // border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    textTransform: "uppercase",
    padding: "6px 11px 6px 18px",
    marginBottom: "6px",
    marginTop: "6px",
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  cdTypeAddButtonLabel: {
    justifyContent: "center",
  },
  cdButtons: {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  cdLabels: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  saveButtonFormGroup: {
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  paddingRight0px: {
    paddingRight: "0px",
  },
  paddingLeft0px: {
    paddingLeft: "0px",
  },
  cancelButtonFormGroup: {
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  containerButtons: {
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  submitButton: {
    color: theme.palette.white.main,
    background: "linear-gradient(90deg, " + theme.palette.primary.main + " 0%, " + theme.palette.primary.mediumBlue + " 100%)",
    border: "0px",
    boxShadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
  },
  detailLabels: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  modalSentRecover: {
    maxWidth: "718px",
    width: "718px",
    height: "571px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  modalResultClose: {
    marginLeft: "90%",
  },
  detailFormLabels: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: theme.palette.gray[700],
  },
  helpIconBox: {
    textAlign: "center",
    color: theme.palette.info.lightgreen,
  },
  helpIconRoot: {
    width: "100px",
    height: "auto",
  },
  modalUpdateLoan: {
    width: "530px",
    height: "600px",
    position: "absolute",
    marginLeft: "25.07%",
    marginRight: "25.07%",
    marginBottom: "31.35%",
  },
  userProfileTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "35px",
    color: theme.palette.gray[700],
    textAlign: "center",
  },
  borderRadiusSemiCurved: {
    borderRadius: "4px 0px 0px 4px",
  },
  userProfileUpdatePasswordButton: {
    width: "100%",
    height: "45px",
    padding: "6px",
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "0px 4px 4px 0px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
    textTransform: "uppercase",
  },
  opportunityAddQuestionsCardAction: {
    marginTop: "0px",
  },
  opportunityAddQuestionsButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "6px 11px",
  },
  CardContentBorderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0,.05)",
  },
  editButton: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "50%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  editButtonTwo: {
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    width: "100%",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    textTransform: "uppercase",
    marginTop: "5px",
  },
  buttonProgress: {
    color: theme.palette.white.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  documentBadge: {
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
  },
  changeButtonsGroup: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "wrap",
      justifyContent: "center",
      marginTop: "1rem",
    },
  },
  changeButtonOf2: {
    //widths to use when there are 2 buttons
    width: "300px",
    [theme.breakpoints.down("lg")]: {
      width: "250px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
    heigth: "43px",
    backgroundColor: theme.palette.white.light,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",
    textTransform: "none",
    
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "none",

      /* Text Color/Dark Blue */

      color: theme.palette.white.main,
    },
  },
  changeButton: {
    width: "250px",
    [theme.breakpoints.down("lg")]: {
      width: "200px",
    },
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "130px",
    },
    heigth: "43px",
    backgroundColor: theme.palette.white.light,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px 0px 0px 4px",
    textTransform: "none",
    
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    /* identical to box height */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Text Color/Dark Blue */

    color: theme.palette.primary.greyBlue,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.greyBlue + " !important",
      /* Color Border/Secondary */

      border: "1px solid " + theme.palette.white.light,
      boxSizing: "border-box",
      borderRadius: "4px",

      
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      /* identical to box height */

      display: "flex",
      alignItems: "center",
      textAlign: "center",
      letterSpacing: "0.02em",
      textTransform: "none",

      /* Text Color/Dark Blue */

      color: theme.palette.white.main,
    },
  },
  tableButtons: {
    padding: "6px 4px 6px 4px",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgb(50 50 93 / 11%), 0px 1px 3px rgb(0 0 0 / 8%)",
    borderRadius: "4px",
    borderColor: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  dividerRoot: {
    height: "4px",
    marginBottom: "1rem",
  },
  dropzone: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "1px",
    borderRadius: "1px",
    height: "62px!important",
    borderColor: theme.palette.gray[600],
    borderStyle: "dashed",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[500],
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  boxFill: {
    marginTop: "10px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.gray[700],
    display: "flex",
    alignItems: "center",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  touchAppIcon: {
    position: "absolute",
    marginLeft: "97%",
    marginTop: "1%",
    backgroundColor: theme.palette.white.smoke,
    width: "25px",
    height: "28px",
  },
  tooltip: {
    maxWidth: "280px",
    marginTop: "1px",
    marginBottom: "1px",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.black.light,
    textAlign: "justify",
  },
  tooltipArrow: {
    color: theme.palette.grey[300],
  },
  documentBadgeContainer: {
    paddingLeft: "15px",
  },
  tooltipDescritionSizeWidth: {
    maxWidth: 500,    
  },
  quillOverflowWrap: {
    "& .ql-container .ql-editor": {
      overflowWrap: "anywhere",
      "& img": {
        maxWidth: "100%",
      },
    },
  },
  tooltipInfoIcon: {
    color: theme.palette.primary.main,
    marginLeft: "5px"
  },
  ratingBox: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  sideBySideDiv: {
    float: "left",
    width: "250px",
  },

  overallRatingIcon: {
    marginLeft: "-10px",
    marginRight: "-10px"
  },

  tabButton: {
    fontWeight: "bold",
    boxShadow: "0px 0px 0px",
    backgroundColor: theme.palette.grey[200],
    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main + "!important",
      color: theme.palette.primary.main + "!important", 
      boxShadow: "0px -2px 1px" + theme.palette.primary.main,
    },
  },
  minHeight0PX:{
    minHeight:"auto!important"
  },
});

export default componentStyles;
