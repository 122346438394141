//compile the other user reducer into this one!
import { USERS } from "utils/actionsConsts";

const usersReducer = (
  state = {
    foUserHistory: [],
    foUserHistory_refresh_results: false,
    backoffice_users_search_loading: false,
    frontoffice_users_search_loading: false,
    register_fo_user_loading: false,
    searchFOUsers_loading: false,
    getFOUserEmploymentHistory_loading: false,
    getFOUserUnemploymentHistory_loading: false,
    total_records: 0,
    total_pages: 0,
    getFoUSInterventionOtherArea_loading: false,
    getFoUSInterventionOtherArea__result: [],
  },
  action
) => {
  switch (action.type) {
    case USERS.GET_BACKOFFICE_FILTERED_SEARCH:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case USERS.GET_FRONTOFFICE_FILTERED_SEARCH:
      return {
        ...state,
        result: action.payload.data,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case USERS.GET_BACKOFFICE_FILTERED_SEARCH_LOADING:
      return {
        ...state,
        backoffice_users_search_loading: action.payload,
        result: action.payload ? [] : state.result,
        total_records: action.payload ? 0 : state.total_records,
      };
    case USERS.GET_FRONTOFFICE_FILTERED_SEARCH_LOADING:
      return {
        ...state,
        frontoffice_users_search_loading: action.payload,
        result: action.payload ? [] : state.result,
        total_records: action.payload ? 0 : state.total_records,
      };
    case USERS.REGISTER_FO_USER_LOADING:
      return { ...state, register_fo_user_loading: action.payload };
    case USERS.SEARCH_FO_USERS:
      return { ...state, searchFOUsers_result: action.payload };
    case USERS.SEARCH_FO_USERS_LOADING:
      return { ...state, searchFOUsers_loading: action.payload };
    case USERS.GET_FO_USER_OVERVIEW:
      return { ...state, getFOUserOverview_result: action.payload };
    case USERS.GET_FO_USER_OVERVIEW_LOADING:
      return { ...state, getFOUserOverview_loading: action.payload };
    case USERS.CREATE_FO_USER_HISTORY_ENTRY:
      return { ...state, addFOUserHistoryEntry: action.payload };
    case USERS.GET_FO_USER_HISTORY:
      return { ...state, foUserHistory: action.payload };
    case USERS.GET_FO_USER_HISTORY_REFRESH_RESULTS:
      return { ...state, foUserHistory_refresh_results: action.payload };
    case USERS.GET_FO_USER_EMPLOYMENT_HISTORY:
      return {
        ...state,
        getFOUserEmploymentHistory_loading: false,
        getFOUserEmploymentHistory_result: action.payload,
      };
    case USERS.GET_FO_USER_EMPLOYMENT_HISTORY_LOADING:
      return { ...state, getFOUserEmploymentHistory_loading: action.payload };
    case USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY:
      return {
        ...state,
        getFOUserUnemploymentHistory_loading: false,
        getFOUserUnemploymentHistory_result: action.payload,
      };
    case USERS.GET_FO_USER_UNEMPLOYMENT_HISTORY_LOADING:
      return { ...state, getFOUserUnemploymentHistory_loading: action.payload };
    case USERS.GET_FOUS_INTERVENTION_OTHER_AREA:
      return {
        ...state,
        getFoUSInterventionOtherArea_loading: false,
        getFoUSInterventionOtherArea_result: action.payload,
      };
    case USERS.GET_FOUS_INTERVENTION_OTHER_AREA_LOADING:
      return { ...state, getFoUSInterventionOtherArea_loading: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
