import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LinkMaterialUi from "@material-ui/core/Link";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import custom components
import DisplayField from "components/Fields/DisplayField";
import GenericButton from "components/Buttons/GenericButton";
import { labels, titles } from "resources/resources";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UserSingularGeneralDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();

  const redirectToUserOverView = (event) => {
    event.preventDefault();
    history.push("/admin/user-overview/" + props.generalData.userId);
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_ApplicationDetail_GeneralDataSection)}
          action={
            <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={redirectToUserOverView}>
              {intl.formatMessage(labels.Label_SeeMore)}
            </GenericButton>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField
              xs={12}
              sm={8}
              md={3}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_NameField)}
              data={props.generalData?.nameOfUser}
            />
            <DisplayField
              xs={12}
              sm={4}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_NumBIField)}
              data={props.generalData?.numBI}
            />
            <DisplayField
              xs={12}
              sm={3}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_GenderField)}
              data={props.generalData?.gender}
            />
            <DisplayField
              xs={12}
              sm={5}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_MaritalStatusField)}
              data={props.generalData?.maritalStatus}
            />
            <Grid item xs={12} sm={4} md={3}>
              <FormGroup>
                <FormLabel className={classes.detailFormLabels}>
                  {intl.formatMessage(labels.Label_Admin_GeneralDataSection_OtherApplicationsField)}
                </FormLabel>
                <Box className={classes.detailLabels}>
                  {props.generalData !== undefined ? props.generalData.otherApplicationsOfUser : 0}
                  <LinkMaterialUi
                    style={{ marginLeft: "50px" }}
                    component={Link}
                    variant="h5"
                    to={`/admin/applications-management/${props.generalData?.numBI ?? ""}`}
                    className={classes.containedGhostButton}
                  >
                    Ver mais
                  </LinkMaterialUi>
                </Box>
              </FormGroup>
            </Grid>
            <DisplayField
              xs={12}
              sm={8}
              md={3}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_SpecialNeedsField)}
              data={props.generalData?.specialNeeds}
            />
            <DisplayField
              xs={12}
              sm={4}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_SNPercentageField)}
              data={(props.generalData?.specialNeedsPercentage ?? 0) + " %"}
            />
            <DisplayField
              xs={12}
              sm={3}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_AgeField)}
              data={props.generalData?.age}
            />
            <DisplayField
              xs={12}
              sm={5}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_BirthdayField)}
              data={props.generalData?.birthDate}
            />
            <DisplayField
              xs={12}
              sm={4}
              md={2}
              label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_MobilePhoneField)}
              data={props.generalData?.mobilePhone}
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(UserSingularGeneralDataSection);
