import { getListFromFormData } from "utils/formSelectMultiple";

// function passwordGenerator() {
//   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//     (
//       c ^
//       (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
//     ).toString(16)
//   );
// }
export function frontOfficeEnterpriseUserRegisterMap(formData) {
  if (formData != null && formData !== undefined)
    return {
      user: {
        id: "",
        userType: 2,
        name: formData.name ? formData.name : "",
        mobilePhone: formData.mobilePhone ? formData.mobilePhone : "",
        alternativeMobilePhone: formData.alternativeMobilePhone ? formData.alternativeMobilePhone : "",
        email: formData.email ? formData.email : "",
        password: formData.password ? formData.password : "",
        userCode: "",
        userColective: {
          contactName: formData.contactName ? formData.contactName : "",
          businessPurposeList: getListFromFormData(formData.businessPurpose) ?? [],
          taxIdNumber: formData.taxIdNumber ? formData.taxIdNumber : "",
          permitCode: formData.permitCode ? formData.permitCode : "",
          permitCodeValidUntil: formData.permitCodeValidUntil ? formData.permitCodeValidUntil : null,
          niss: formData.niss ? formData.niss : "",
          experience: formData.experience ? formData.experience : null,
          companyEmail: formData.companyEmail ? formData.companyEmail : "",
          companyPhone: formData.companyPhone ? formData.companyPhone : "",
          compositeAddress: {
            provinceId: formData.province ? formData.province.code : null,
            provinceKey: formData.province ? formData.province.keyValue : "",
            municipalityId: formData.municipality ? formData.municipality.code : null,
            district: formData.district ? formData.district : "",
            address: formData.address ? formData.address : "",
          },
          companyEACId: formData.companyEACId ? formData.companyEACId.code : null,
          propertyType: formData.propertyType ?? "",
          subsidiaryCompany: formData.subsidiaryCompany ?? "",
          subsidiaryCompanyPostalCode: formData.subsidiaryCompanyPostalCode ?? "",
          subsidiaryCompanyActivity: formData.subsidiaryCompanyActivity ?? "",
          subsidiaryCompanyEACId: formData.subsidiaryCompanyEACId?.code ?? null,
          maleWorkersNumber: formData.maleWorkersNumber ?? null,
          femaleWorkersNumber: formData.femaleWorkersNumber ?? null,
        },
      },
    };
  return formData;
}

export default frontOfficeEnterpriseUserRegisterMap;
