import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import { labels } from "resources/resources";
//styles
import componentStyles from "assets/theme/views/admin/generic.js";

const useStyles = makeStyles(componentStyles);

function RejectDialog(props) {
  const classes = { ...useStyles() };
  const theme = useTheme();
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmitReject = (data) => {
    props.onSubmitRejectDialog(data?.motive?.code, data?.notes);
  };

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitReject)}>
          <Box className={classes.modalHelpIconBox}>
            <HelpOutlineIcon className={classes.modalHelpIconRoot} />
          </Box>

          <Container>
            <Box className={classes.modalHeader} marginBottom="30px!important">
              {props.title}
            </Box>

            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel className={classes.boxNameCategory}>Motivo</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="motive"
                    selectOptions={props.rejectionMotivesList}
                    setValue={setValue}
                    getValues={getValues}
                    required={true}
                    requiredMessage={"O campo é obrigatório"}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["motive"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["motive"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Comentários</FormLabel>
                  <Controller
                    name="notes"
                    control={control}
                    component={Box}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={"Escreva aqui os seus comentários"}
                        multiline={true}
                        rows={3}
                        inputProps={{ maxLength: 300 }}
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Container>

          <Box textAlign="center">
            <GenericButton typeSubmit={true} color="primary" loading={props?.isLoading}>
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(RejectDialog);
