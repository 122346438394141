import { React, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import clsx from "clsx";

import ReactDatetime from "react-datetime";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Card, CardHeader, CardContent, Tooltip, FormHelperText } from "@material-ui/core";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
import { DateRange } from "@material-ui/icons";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import { getAllOpportunityApplicationsAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET } from "utils/consts";
import { getAllOpportunityApplicationsMap } from "mappers/opportunityMap";
import { getDatasetStateByName } from "utils/datasets";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import getReportAction from "redux/actions/reportsAction";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS } from "utils/consts";
import { REPORT_CONSTS } from "utils/consts";
import { REPORT_FILE_NAME } from "utils/consts";
import { opportunitiesApplicationExportReportMap } from "mappers/opportunityMap";
import errorMessages from "resources/resources";
import { messages } from "resources/resources";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import useTokenHelper from "hooks/useTokenHelper";
import { REGEX_PATTERNS } from "utils/consts"

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsFilterSection(props) {
  const tokenHelper = useTokenHelper();

  const classes = useStylesGeneric();
  const intl = useIntl();
  const theme = useTheme();
  const [filter, setFilter] = useState({
    applicationCode: null,
    opportunityType: null,
    searchText: null,
    applicationState: null,
    opportunityReference: null,
    userNameOrIdentityCard: null,
    companyName: null,
    companyTaxIdNumber: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
    municipality: tokenHelper.getMunicipalityId(),
    employmentCenter: tokenHelper.getEmploymentCenterId(),
    genderId: null,
    academicLevelId: null,
    degreeId: null,
    startDate: null,
    endDate: null,
    province: tokenHelper.getProvinceId(),
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const handleInputValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  function handleGetDataSetByName(datasetName) {
    props.getDataSetByName(datasetName);
  }

  useEffect(() => {
    handleGetDataSetByName(DATASET.PROVINCES);
    handleGetDataSetByName(DATASET.MUNICIPALITIES);
    handleGetDataSetByName(DATASET.EMPLOYMENT_CENTERS);
    handleGetDataSetByName(DATASET.OPPORTUNITY_TYPE);
    handleGetDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_STATE);
    handleGetDataSetByName(DATASET.GENDER);
    handleGetDataSetByName(DATASET.ACADEMIC_LEVEL);
    handleGetDataSetByName(DATASET.ACADEMIC_DEGREE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.dataSetState) {
      var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES] ?? [];

      if (tokenHelper.getProvinceId()) {
        setValue("municipality", getSelectOptionsElementByCode(municipalityList, tokenHelper.getMunicipalityId()));
      } else {
        setValue(
          "municipality",
          getSelectOptionsElementByCode(municipalityList, props.opportunityDetailsData?.municipalityId)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);

  useEffect(() => {
    if (props.dataSetState) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ?? [];

      if (tokenHelper.getProvinceId()) {
        setValue("province", getSelectOptionsElementByCode(provinceList, tokenHelper.getProvinceId()));
      } else {
        setValue("province", getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.dataSetState) {
      var provinceList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS] ?? [];

      if (tokenHelper.getEmploymentCenterId()) {
        setValue("employmentCenter", getSelectOptionsElementByCode(provinceList, tokenHelper.getEmploymentCenterId()));
      } else {
        setValue(
          "employmentCenter",
          getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.EMPLOYMENT_CENTERS]]);

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  const cleanFilter = () => {
    setValue("applicationCode", "");
    setValue("opportunityType", undefined);
    setValue("searchText", "");
    setValue("applicationState", undefined);
    setValue("opportunityReference", "");
    setValue("userNameOrIdentityCard", "");
    setValue("companyName", "");
    setValue("companyTaxIdNumber", "");
    setValue("gender", undefined);
    setValue("academicLevel", undefined);
    setValue("degree", undefined);
    setValue("startDate", undefined);
    setValue("endDate", undefined);

    if (!tokenHelper.getProvinceId()) setValue("province", undefined);
    if (!tokenHelper.getMunicipalityId()) setValue("municipality", undefined);
    if (!tokenHelper.getEmploymentCenterId()) setValue("employmentCenter", undefined);

    setFilter(0);
    setFilterCounter(0);
  };

  //Refresh Results
  const refreshResults = () => {
    let temp_filter = { ...filter, pageIndex: props.page };
    setFilter(temp_filter);
    props.getAllOpportunityApplications(temp_filter);
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const onSubmit = (data) => {
    let filter = getAllOpportunityApplicationsMap(data);
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getAllOpportunityApplications(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  const employmentCenterBlocked = useMemo(() => {
    if (tokenHelper.getEmploymentCenterId()) {
      return true;
    }

    if (!tokenHelper.getEmploymentCenterId() && !getValues("province")) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("province"), tokenHelper]);

  const municipalityBlocked = useMemo(() => {
    if (tokenHelper.getMunicipalityId()) {
      return true;
    }

    if (!tokenHelper.getMunicipalityId() && !getValues("province")) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("province"), tokenHelper]);

  const provinceBlocked = useMemo(() => {
    if (tokenHelper.getProvinceId()) {
      return true;
    }

    return false;
  }, [tokenHelper]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Candidaturas</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Nº Candidatura</FormLabel>
                  <Controller
                    name="applicationCode"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("applicationCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Estado</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="applicationState"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_CANDIDATE_STATE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"applicationState"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Nº Oferta</FormLabel>
                  <Controller
                    name="opportunityReference"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("opportunityReference", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_TYPE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"opportunnityType"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Nome ou Nº BI</FormLabel>
                  <Controller
                    name="userNameOrIdentityCard"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("userNameOrIdentityCard", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Empresa</FormLabel>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("companyName", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>NIF</FormLabel>
                  <Controller
                    name="companyTaxIdNumber"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("companyTaxIdNumber", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    disabled={provinceBlocked}
                    selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipality"
                    childrenList={[{ name: "employmentCenter", dataset: DATASET.EMPLOYMENT_CENTERS }]}
                    errors={errors}
                    classes={classes}
                    filterListKey="province"
                    handleSetFilter={handleSetFilter}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <FormSelect
                    control={control}
                    disabled={municipalityBlocked}
                    fieldName="municipality"
                    selectOptions={getDatasetStateByName(props, DATASET.MUNICIPALITIES)}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    classes={classes}
                    filterListKey="municipality"
                    handleSetFilter={handleSetFilter}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Opportunity_OpportunityData_EmploymentCenter)}</FormLabel>
                  <FormSelect
                    disabled={employmentCenterBlocked}
                    control={control}
                    fieldName="employmentCenter"
                    selectOptions={getDatasetStateByName(props, DATASET.EMPLOYMENT_CENTERS)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    filterListKey={"employmentCenter"}
                    handleSetFilter={handleSetFilter}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_Admin_GeneralDataSection_GenderField)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="gender"
                    selectOptions={
                      props.dataSetState[DATASET.GENDER] !== undefined
                        ? props.dataSetState[DATASET.GENDER]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"gender"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicLevel)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="academicLevel"
                    selectOptions={
                      props.dataSetState[DATASET.ACADEMIC_LEVEL] !== undefined
                        ? props.dataSetState[DATASET.ACADEMIC_LEVEL]
                        : []
                    }
                    loadChildrenDataSet={props.getDataSetByName}
                    childrenDataSet={DATASET.ACADEMIC_DEGREE}
                    childrenSelect="academicDegree"
                    getValues={getValues}
                    setValue={setValue}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"academicLevel"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormGroup>
                  <FormLabel>{intl.formatMessage(labels.Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicDegree)}</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="academicDegree"
                    selectOptions={
                      props.dataSetState[DATASET.ACADEMIC_DEGREE] !== undefined
                        ? props.dataSetState[DATASET.ACADEMIC_DEGREE]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    disabled={!getValues("academicLevel")}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"academicDegree"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Data Submissão (desde)</FormLabel>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment().startOf("day") ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isLessThanEndDate: (value) => {
                          if (!getValues("endDate") || errors["endDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("endDate")._isAMomentObject &&
                              value <= getValues("endDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateFromLessThanOrEqualToDateToField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("endDate")) trigger("endDate");
                          trigger("startDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment().startOf("day") &&
                            (!getValues("endDate") ||
                              (getValues("endDate")._isAMomentObject && currentDate <= getValues("endDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["startDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["startDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["startDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Data Submissão (até)</FormLabel>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      validate: {
                        isValidDate: (value) => {
                          return (
                            !value ||
                            value._isAMomentObject ||
                            intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat)
                          );
                        },
                        maxDate: (value) => {
                          return (
                            !value ||
                            value <= moment() ||
                            intl.formatMessage(messages.Message_Generic_DateLessThanOrEqualToTodayField)
                          );
                        },
                        isGreaterThanStartDate: (value) => {
                          if (!getValues("startDate") || errors["startDate"] !== undefined) return true;
                          return (
                            !value ||
                            (value !== "" &&
                              value._isAMomentObject &&
                              getValues("startDate")._isAMomentObject &&
                              value >= getValues("startDate")) ||
                            intl.formatMessage(messages.Message_Generic_DateToGreaterThanOrEqualToDateFromField)
                          );
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <ReactDatetime
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          if (getValues("startDate")) trigger("startDate");
                          trigger("endDate");
                        }}
                        locale="pt"
                        dateFormat={"DD/MM/YYYY"}
                        timeFormat={false}
                        isValidDate={(currentDate, _) => {
                          return (
                            currentDate <= moment() &&
                            (!getValues("startDate") ||
                              (getValues("startDate")._isAMomentObject && currentDate >= getValues("startDate")))
                          );
                        }}
                        closeOnSelect={true}
                        closeOnTab={true}
                        closeOnClickOutside={true}
                        inputProps={{
                          placeholder: "DD/MM/AAAA",
                          className: "",
                          value: value && value._isAMomentObject ? value.format("DD/MM/YYYY") : value ?? "",
                        }}
                        renderInput={(dateInputProps, open) => (
                          <OutlinedInput
                            style={{ display: "flex" }}
                            onFocus={open}
                            {...dateInputProps}
                            onChange={(e) => {
                              handleInputValidDate(e.target.value, dateInputProps.onChange);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                              </InputAdornment>
                            }
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]: errors["endDate"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                  {errors["endDate"] !== undefined && (
                    <FormHelperText
                      component={Box}
                      color={theme.palette.warning.badge + "!important"}
                      bgcolor={theme.palette.warning.badgeBg + "!important"}
                      borderRadius="25px"
                      padding={"0px 10px"}
                    >
                      {errors["endDate"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              {/* Butões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.EXPORT_REPORTS) && (
                  <Tooltip
                    title={
                      props.opportunitiesState?.opportunityApplications_result?.total_records >
                        REPORT_CONSTS.MAX_RESULTS ||
                      props.opportunitiesState?.opportunityApplications_result?.total_records === 0
                        ? `Limite mínimo de 1 e máximo de ${REPORT_CONSTS.MAX_RESULTS} resultados para exportar relatório`
                        : ""
                    }
                  >
                    <span>
                      <GenericButton
                        color="secondary"
                        loading={props.reportsState.get_report_loading}
                        disabled={
                          props.opportunitiesState?.opportunityApplications_result?.total_records >
                            REPORT_CONSTS.MAX_RESULTS ||
                          props.opportunitiesState?.opportunityApplications_result?.total_records === 0
                        }
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                          props.getReport(
                            opportunitiesApplicationExportReportMap(filter),
                            REPORT_FILE_NAME.OPPORTUNITY_APPLICATIONS_SEARCH_RESULTS +
                              moment().format("YYYYMMDDHHmm") +
                              REPORT_FILE_NAME.EXCEL_EXTENSION
                          );
                        }}
                      >
                        {intl.formatMessage(labels.Label_ExportButton)}
                      </GenericButton>
                    </span>
                  </Tooltip>
                )}
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.opportunitiesState?.getAllOpportunityApplications_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getAllOpportunityApplications: (data) => dispatch(getAllOpportunityApplicationsAction(data)),
  getDataSetByName: (data, parentKeyValue) => dispatch(getDataSetByNameAction(data, parentKeyValue)),
  getReport: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OpportunityApplicationsFilterSection));
