import { USER_TYPE } from "utils/consts";

export function userFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    formData.email = formData.email ?? "";
    formData.nameOfUser = formData.nameOfUser ?? "";
    formData.numBI = formData.numBI ?? "";
    formData.provinceId = formData.provinceId
      ? parseInt(formData.provinceId.code)
      : undefined;
    formData.municipalityId = formData.municipalityId
      ? parseInt(formData.municipalityId.code)
      : undefined;
    formData.phoneNumber = formData.phoneNumber
      ? formData.phoneNumber
      : undefined;
    console.log("formData.typeOfUser", formData.typeOfUser);
    formData.typeOfUser =
      (formData.typeOfUser?.label === "Individual"
        ? USER_TYPE.INDIVIDUAL
        : formData.typeOfUser?.label === "Colectivo"
        ? USER_TYPE.COLETIVO
        : null) ?? null;
    formData.isActive =
        (formData.isActive?.label === "Activo"
          ? "true"
          : formData.isActive?.label === "Inactivo"
          ? "false"
          : "") ?? "";

    return formData;
  }
}

export default userFilterMap;
