import moment from "moment";
export function frontOfficeEnterpriseUserUpdateMap(formData, emailChanged, userId) {
  if (formData.email !== emailChanged) {
    var auxEmail = formData.email;
  } else {
    auxEmail = "";
  }
  if (formData != null && formData !== undefined) {
    const data = new FormData();
    const user = {
      id: userId,
      userType: 2,
      name: formData.name ? formData.name : "",
      mobilePhone: formData.mobilePhone ? formData.mobilePhone : "",
      alternativeMobilePhone: formData.alternativeMobilePhone ? formData.alternativeMobilePhone : "",
      email: auxEmail,
      password: "",
      isActive: formData.isActive.code,
    };

    const userColective = {
      contactName: formData.contactName ? formData.contactName : "",
      taxIdNumber: formData.taxIdNumber ? formData.taxIdNumber : "",
      permitCode: formData.permitCode ? formData.permitCode : "",
      permitCodeValidUntil: formData.permitCodeValidUntil
        ? moment(formData.permitCodeValidUntil).format("YYYY-MM-DD")
        : "",
      niss: formData.niss ? formData.niss : "",
      experience: formData.experience ? formData.experience : 0,
      companyEmail: formData.companyEmail ? formData.companyEmail : "",
      companyPhone: formData.companyPhone ? formData.companyPhone : "",
      companyEACId: formData.companyEACId ? formData.companyEACId.code : 0,
      propertyType: formData.propertyType ?? "",
      subsidiaryCompany: formData.subsidiaryCompany ?? "",
      subsidiaryCompanyPostalCode: formData.subsidiaryCompanyPostalCode ?? "",
      subsidiaryCompanyActivity: formData.subsidiaryCompanyActivity ?? "",
      subsidiaryCompanyEACId: formData.subsidiaryCompanyEACId?.code,
      maleWorkersNumber: formData.maleWorkersNumber ?? "",
      femaleWorkersNumber: formData.femaleWorkersNumber ?? "",
    };

    const compositeAddress = {
      provinceId: formData.province ? formData.province.code : 0,
      municipalityId: formData.municipality ? formData.municipality.code : 0,
      district: formData.district ? formData.district : "",
      address: formData.address ? formData.address : "",
    };

    //Append data
    data.append("id", userId);

    for (const key in user) {
      data.append("user." + key, user[key]);
    }

    for (const key in userColective) {
      data.append("user.userColective." + key, userColective[key]);
    }

    for (const key in compositeAddress) {
      data.append("user.userColective.compositeAddress." + key, compositeAddress[key]);
    }
    //Lists need to be treated seperately
    formData.businessPurpose?.forEach((element) => {
      data.append("user.userColective.businessPurposeList", element.code);
    });

    return data;
  }
  return formData;
}

export default frontOfficeEnterpriseUserUpdateMap;
