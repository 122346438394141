import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import BackOfficeUserData from "./Sections/BackOfficeUserData";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getBackOfficeUserInfoAction, updateBoUserAction } from "redux/actions/usersAction";
import backOfficeUserUpdateMap from "mappers/backOfficeUserUpdateMap";
import { DATASET } from "utils/consts";
import {
  getSelectOptionsElementByCode,
  getMultipleSelectOptionsElementByLabel,
  getMultipleSelectOptionsElementByCode,
} from "utils/formSelect";
import useScrollDirection from "hooks/useScrollDirection";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericButton from "components/Buttons/GenericButton";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function EditBackOfficeUser(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();
  const [isEditable, setIsEditable] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    trigger,
    getValues,
    unregister,
    formState: { errors },
  } = methods;

  const handleCancelOnClickEvent = () => {
    history.goBack();
  };

  function handleRegister(data) {
    let map = backOfficeUserUpdateMap(data, props.match.params.userId);
    props.updateBoUser(map);
  }

  const handleEditSection = () => {
    setIsEditable(!isEditable);
    if (isEditable) {
      methods.clearErrors();
    }
  };

  React.useEffect(() => {
    props.getBackOfficeUserInfo(props.match.params.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userId]);

  React.useEffect(() => {
    setFormValuesOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersInfo.result]);

  const setFormValuesOnMount = () => {
    var active = props.usersInfo.result !== undefined ? props.usersInfo.result.active : null;
    setValue(
      "active",
      DATASET.YES_OR_NO_DROPDOWN.find((t) => t.code === active)
    );

    setValue("name", props.usersInfo.result !== undefined ? props.usersInfo.result.name : null);
    setValue("surname", props.usersInfo.result !== undefined ? props.usersInfo.result.surname : null);

    props.getDataSet(DATASET.PROVINCES);
    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    var provinces = props.usersInfo.result !== undefined ? props.usersInfo?.result?.province : null;
    var selectedProvince = getSelectOptionsElementByCode(provinceList, provinces);
    setValue("province", selectedProvince);

    if (selectedProvince) props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : [];

    var municipalities = props.usersInfo.result !== undefined ? props.usersInfo.result.municipality : null;

    setValue("county", getSelectOptionsElementByCode(municipalityList, Number.parseInt(municipalities)));

    setValue("email", props.usersInfo.result !== undefined ? props.usersInfo.result.email : null);
    setValue("emailConfirmation", props.usersInfo.result !== undefined ? props.usersInfo.result.email : null);

    var rolesList = props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : [];

    var roles = props.usersInfo.result !== undefined ? props.usersInfo.result.roles : null;
    setValue("function", getMultipleSelectOptionsElementByLabel(rolesList, roles));

    var banksList = props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : [];
    var managerBankId = props.usersInfo.result !== undefined ? props.usersInfo.result.managerBankId : null;
    setValue("managerBank", getSelectOptionsElementByCode(banksList, managerBankId));

    var loanTypesList = props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : [];
    var managerLoanTypes = props.usersInfo.result !== undefined ? props.usersInfo.result.managerLoanTypes : null;
    setValue("managerLoanTypes", getMultipleSelectOptionsElementByCode(loanTypesList, managerLoanTypes));

    props.getDataSet(DATASET.EMPLOYMENT_CENTERS, selectedProvince?.keyValue);
    var employmentCenterList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      : [];
    var selectedEmploymentCenter = getSelectOptionsElementByCode(
      employmentCenterList,
      props.usersInfo?.result?.employmentCenterId
    );
    setValue("employmentCenter", selectedEmploymentCenter);
  };

  React.useEffect(() => {
    if (props.dataSetState) {
      var rolesList = props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : [];

      var roles = props.usersInfo.result !== undefined ? props.usersInfo.result.roles : null;
      setValue("function", getMultipleSelectOptionsElementByLabel(rolesList, roles));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.BACKOFFICE_ROLES]]);

  React.useEffect(() => {
    if (props.dataSetState) {
      var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES]
        ? props.dataSetState[DATASET.MUNICIPALITIES]
        : [];

      var municipalities = props.usersInfo.result !== undefined ? props.usersInfo.result.municipality : null;
      setValue("county", getSelectOptionsElementByCode(municipalityList, Number.parseInt(municipalities)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);

  React.useEffect(() => {
    if (props.dataSetState) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];

      var provinces = props.usersInfo.result !== undefined ? props.usersInfo.result.province : null;

      setValue("province", getSelectOptionsElementByCode(provinceList, Number.parseInt(provinces)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  React.useEffect(() => {
    if (props.dataSetState) {
      var banksList = props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : [];
      var managerBankId = props.usersInfo.result !== undefined ? props.usersInfo.result.managerBankId : null;
      setValue("managerBank", getSelectOptionsElementByCode(banksList, managerBankId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.BANK]]);

  React.useEffect(() => {
    if (props.dataSetState) {
      var loanTypesList = props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : [];
      var managerLoanTypes = props.usersInfo.result !== undefined ? props.usersInfo.result.managerLoanTypes : null;
      setValue("managerLoanTypes", getMultipleSelectOptionsElementByCode(loanTypesList, managerLoanTypes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.LOAN_TYPE]]);

  React.useEffect(() => {
    if (props.dataSetState) {
      var employmentCenterList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        : [];
      var selectedEmploymentCenter = getSelectOptionsElementByCode(
        employmentCenterList,
        props.usersInfo?.result?.employmentCenterId
      );
      setValue("employmentCenter", selectedEmploymentCenter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.EMPLOYMENT_CENTERS]]);

  return (
    <>
      <GenericBackdrop open={props.usersInfo.getUserInfoBO_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes Utilizador BackOffice"}
            subsection={"Gestão de Utilizadores BackOffice"}
            subsectionLink={"/admin/backofficeusers-management"}
          />
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleRegister)}>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
            <PageTitleHeader title={"Detalhes Utilizador BackOffice"} />

            <Container maxWidth={false} component={Box}>
              <BackOfficeUserData
                isCreate={false}
                userId={props.match.params.userId}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
                unregister={unregister}
                handleEditSection={handleEditSection}
                isEditable={isEditable}
              />

              {isEditable && (
                <Grid container>
                  <Grid item xs={12} lg={6} xl={8}></Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton typeSubmit={false} color="secondary" onClick={() => handleCancelOnClickEvent()}>
                        {intl.formatMessage(labels.Label_Generic_Cancel)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                        loading={props.usersInfo.backOfficeUserUpdate_loading}
                      >
                        {intl.formatMessage(labels.Label_Update_Submit)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Container>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateBoUser: (data) => dispatch(updateBoUserAction(data)),
  getBackOfficeUserInfo: (id) => dispatch(getBackOfficeUserInfoAction(id)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBackOfficeUser);
